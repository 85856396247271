<template>
  <div>
    <b-button
      class="red previous-button"
      variant="link"
      aria-label="previous button"
      @click="goToPreviousSlide"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-left"
      >
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
        {{ buttonText }}
    </b-button>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'PreviousButtonComponent',
  props: {
    buttonText: {
      type: String,
      default: 'Previous',
    },
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'setPreviousSlide',
    ]),
    goToPreviousSlide() {
      this.setPreviousSlide()
    },
  },
}
</script>
<style scoped>
.previous-button {
  color: #2FB2AE;
}
</style>
